/* eslint-disable @next/next/no-img-element */
"use client";

import React from 'react';
import { User, createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { useEffect, useState } from 'react';
import DataInputForm from './dashboard/DataInputForm';
import Features from "./Features";
import { RequestProps, ResultType } from "@/types/service-invoke";
import { useLogSnag } from "@logsnag/next";

const Hero = () => {

  const supabase = createClientComponentClient();
  const [user, setUser] = useState<User>(null);
  const [systemLanguage, setSystemLanguage] = useState("English");
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [selectedDocType, setSelectedDocType] = useState<ResultType>(undefined);

  useEffect(() => {
    const roleToDocTypeMap = {
      undefined: undefined,
      'Developer': 'Software System',
      'Product Manager': 'Function Design',
      'Marketing Manager': 'Marketing Plan',
      'Entrepreneur': 'Business Plan',
      'Software Tester': 'Test Case',
    } as Record<string, ResultType>;
    // @ts-ignore
    setSelectedDocType(roleToDocTypeMap[selectedRole]);
  }, [selectedRole]);

  //FIXME put the same logi to a util module
  const storedPayload = (typeof window !== 'undefined') ?
    localStorage.getItem('requestPayload') : null;
  const payloadObject = storedPayload ? JSON.parse(storedPayload) : null;


  const [formData, setFormData] = useState<RequestProps>({
    nodeDescription: payloadObject?.nodeDescription || "",
    currentLevel: payloadObject?.currentLevel || 3,
    nodeType: payloadObject?.nodeType || "Product",
    resultType: payloadObject?.resultType,
    resultLanguage: payloadObject?.resultLanguage || "English",
    serviceLevel: 1,
  });

  const { track } = useLogSnag();

  useEffect(() => {
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();

      if (data && data.user) {
        setUser(data.user);
      }
    };

    getUser();
  }, [supabase]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to map browser language code to your system's language format
  function mapBrowserLanguageToSystemLanguage(browserLang: string) {
    const languageMap = {
      // English mappings
      'en': 'English',
      'en-US': 'English', // United States English
      'en-GB': 'English', // Great Britain English
      'en-AU': 'English', // Australian English
      'en-CA': 'English', // Canadian English
      'en-NZ': 'English', // New Zealand English
      'en-IE': 'English', // Irish English
      'en-ZA': 'English', // South African English

      // Chinese mappings
      'zh': 'Chinese',    // Generic Chinese
      'zh-CN': 'Chinese', // Mainland China, Simplified Chinese
      'zh-SG': 'Chinese', // Singapore, Simplified Chinese
      'zh-TW': 'Chinese', // Taiwan, Traditional Chinese
      'zh-HK': 'Chinese', // Hong Kong, Traditional Chinese
      'zh-MO': 'Chinese', // Macau, Traditional Chinese
    } as Record<string, string>;

    // Default to English if no matching language is found
    return languageMap[browserLang as string] || 'English';
  }

  useEffect(() => {
    const userBrowserLanguage = navigator.language;
    const systemLanguage = mapBrowserLanguageToSystemLanguage(userBrowserLanguage);
    setSystemLanguage(systemLanguage);
  }, []);

  // Function to handle form submission
  const handleSubmit = async () => {
    // If user already login, just redirect to /dashboard page
    const payload = {
      requestUserID: user?.id,
      nodeDescription: formData.nodeDescription,
      nodeName: "",
      currentLevel: 1,
      nodeType: "children",
      resultType: selectedDocType,
      resultLanguage: systemLanguage,
      userEmail: user?.email,
      emailAfterCompletion: 1,
      serviceLevel: 1,
    } as RequestProps;

    localStorage.setItem('requestPayload', JSON.stringify(payload));

    track({
      channel: "doc_gen_attempt",
      event: "Landing Page Doc generate Attempt",
      user_id: user?.id,
      description: `User ${user?.email ?? ""} attempted to generate doc`,
      icon: "📄",
      tags: {
        user: user?.id,
        email: user?.email,
        description: formData.nodeDescription.substring(0, 158),
        result_type: formData.resultType,
        referer: sessionStorage?.getItem('referer'),
        language: systemLanguage,
      }
    });

    window.location.href = "/signin";
  };

  return (
    <section className="flex flex-col items-center justify-between bg-base-100 px-8 py-8 max-w-7xl mx-auto">
      <div className="flex-1 flex flex-col gap-8 items-center">
        <h1 className="text-center font-extrabold text-3xl lg:text-6xl leading-tight">Save more Time, Earn more Money</h1>

        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded-lg shadow-md mb-8 w-full max-w-2xl">
          <h2 className="font-bold text-xl mb-2">Boost Your Productivity with AI Employees!</h2>
          <p className="mb-4">Imagine having AI colleagues working 24/7. We&apos;re making it a reality with AiAlly!</p>
          <a 
            href="https://getaially.com" 
            target="_blank"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <span>Reserve Now for $19.9</span>
            <span className="ml-2">→</span>
          </a>
          <p className="mt-2 text-sm">Lock in 50% lifetime discount. Launching soon!</p>
        </div>

        <div className="text-center font-bold text-xl lg:text-2xl leading-tight px-4">
          <div className="flex flex-col lg:flex-row justify-center items-center gap-2 lg:gap-4">
            <div className="flex justify-center items-center gap-2">
              <p className="mb-0 lg:mb-0">I am Working as</p>
              <select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                className="select select-bordered"
              >
                <option value={undefined}>Your Role</option>
                <option value="Developer">Developer</option>
                <option value="Product Manager">Product Manager</option>
                <option value="Marketing Manager">Marketing Manager</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Software Tester">Software Tester</option>
              </select>
            </div>
            <p className="mb-2 lg:mb-0">and Want to Create a</p>
            <div className="flex justify-center items-center gap-2">
              <select
                value={selectedDocType}
                onChange={(e) => setSelectedDocType(e.target.value as ResultType)}
                className="select select-bordered"
              >
                <option value={undefined}>The Doc You Would Like to Write</option>
                <option value="Marketing Plan">Marketing Plan</option>
                <option value="Software System">Software System</option>
                <option value="Function Design">Function Design</option>
                <option value="Test Case">Test Case</option>
                <option value="Business Plan">Business Plan</option>
              </select>
              <p>Document</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-4 mt-10 lg:mt-0 lg:ml-10">
        <div className="bg-base-100 p-4">
          <DataInputForm
            formData={{
              nodeDescription: formData.nodeDescription,
              currentLevel: formData.currentLevel,
              serviceLevel: 1,
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isDownloadReady={false}
            submitted={false}
            page='landing'
          />
        </div>
        <Features />
      </div>
    </section >
  );
};

export default Hero;
